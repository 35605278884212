<template>
  <MaterialCard color="primary">
    <template v-slot:heading>
      <v-card-title class="pa-1 pl-4" dense style="font-size:1em;">
        {{ instance_info.instance_name }}
        <v-spacer></v-spacer>
        <div style="font-size:.7em;" class="ml-2">
          <span class="mr-1">{{ sensor_count }}</span
          >&nbsp; Active Sensor

          <span class="mr-1 ml-4">{{ urls_count.all }}</span
          >&nbsp; URLs Monitored
        </div>
      </v-card-title>
    </template>
    <v-card-text class="ma-0 pa-0">
      <div
        v-for="sensor in instance_info.sensors"
        :key="sensor.name"
        class="pa-4 pt-0"
      >
        <span style="font-size:.85em;">{{ sensor.name }}</span>
        <br />
        <status-indicator
          v-if="urls_count.http.urls > 0"
          status="positive"
          :pulse="true"
          class="mr-2 ml-4"
        />
        <status-indicator v-else :pulse="false" class="mr-2 ml-4" />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">HTTP</span>
          </template>
          <span>{{ urls_count.http.last }}</span>
        </v-tooltip>
        <span style="font-size:.7em;" class="green--text ml-1 mr-1"
          >{{ urls_count.http.urls }} URLs</span
        >

        <status-indicator
          v-if="urls_count.ldap.urls > 0"
          status="positive"
          :pulse="true"
          class="mr-2 ml-6"
        />
        <status-indicator v-else :pulse="false" class="mr-2 ml-6" />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">LDAP</span>
          </template>
          <span>{{ urls_count.ldap.last }}</span>
        </v-tooltip>
        <span style="font-size:.7em;" class="green--text ml-1 mr-1"
          >{{ urls_count.ldap.urls }} URLs</span
        >

        <status-indicator
          v-if="urls_count.ocsp.urls > 0"
          status="positive"
          :pulse="true"
          class="mr-2 ml-6"
        />
        <status-indicator v-else :pulse="false" class="mr-2 ml-6" />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">OCSP</span>
          </template>
          <span>{{ urls_count.ocsp.last }}</span>
        </v-tooltip>

        <span style="font-size:.7em;" class="green--text ml-1 mr-1"
          >{{ urls_count.ocsp.urls }} URLs</span
        >
      </div>
    </v-card-text>
  </MaterialCard>
</template>

<script>
import MaterialCard from "./MaterialCard";
import { StatusIndicator } from "vue-status-indicator";
export default {
  name: "LiveInstance",
  components: { MaterialCard, StatusIndicator },
  props: ["instance_info", "color"],
  computed: {
    sensor_count: function() {
      return Object.keys(this.instance_info.sensors).length;
    },
    urls_count: function() {
      var http = { urls: 0, per_minute: 0, last: null },
        ldap = { urls: 0, per_minute: 0, last: null },
        ocsp = { urls: 0, per_minute: 0, last: null };
      for (const [key, value] of Object.entries(this.instance_info.sensors)) {
        console.log(key);
        if ("http" in value["types"]) {
          let obj = value["types"]["http"];
          http.urls = obj.urls;
          http.last = obj;
          /*
          let seconds = moment
            .duration(moment(obj.processed).diff(moment(obj.created)))
            .asSeconds();
          http.per_minute = 60 * (obj.count / seconds);

           */
        }
        if ("ldap" in value["types"]) {
          let obj = value["types"]["ldap"];
          ldap.urls = obj.urls;
          ldap.last = obj;
        }
        if ("ocsp" in value["types"]) {
          let obj = value["types"]["ocsp"];
          ocsp.urls = obj.urls;
          ocsp.last = obj;
        }
      }
      return {
        http: http,
        ldap: ldap,
        ocsp: ocsp,
        all: http.urls + ldap.urls + ocsp.urls
      };
    }
  },
  data: function() {
    return {};
  }
};
</script>

<style scoped></style>
