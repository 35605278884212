var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MaterialCard',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-card-title',{staticClass:"pa-1 pl-4",staticStyle:{"font-size":"1em"},attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.instance_info.instance_name)+" "),_c('v-spacer'),_c('div',{staticClass:"ml-2",staticStyle:{"font-size":".7em"}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.sensor_count))]),_vm._v(" Active Sensor "),_c('span',{staticClass:"mr-1 ml-4"},[_vm._v(_vm._s(_vm.urls_count.all))]),_vm._v(" URLs Monitored ")])],1)]},proxy:true}])},[_c('v-card-text',{staticClass:"ma-0 pa-0"},_vm._l((_vm.instance_info.sensors),function(sensor){return _c('div',{key:sensor.name,staticClass:"pa-4 pt-0"},[_c('span',{staticStyle:{"font-size":".85em"}},[_vm._v(_vm._s(sensor.name))]),_c('br'),(_vm.urls_count.http.urls > 0)?_c('status-indicator',{staticClass:"mr-2 ml-4",attrs:{"status":"positive","pulse":true}}):_c('status-indicator',{staticClass:"mr-2 ml-4",attrs:{"pulse":false}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("HTTP")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.urls_count.http.last))])]),_c('span',{staticClass:"green--text ml-1 mr-1",staticStyle:{"font-size":".7em"}},[_vm._v(_vm._s(_vm.urls_count.http.urls)+" URLs")]),(_vm.urls_count.ldap.urls > 0)?_c('status-indicator',{staticClass:"mr-2 ml-6",attrs:{"status":"positive","pulse":true}}):_c('status-indicator',{staticClass:"mr-2 ml-6",attrs:{"pulse":false}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("LDAP")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.urls_count.ldap.last))])]),_c('span',{staticClass:"green--text ml-1 mr-1",staticStyle:{"font-size":".7em"}},[_vm._v(_vm._s(_vm.urls_count.ldap.urls)+" URLs")]),(_vm.urls_count.ocsp.urls > 0)?_c('status-indicator',{staticClass:"mr-2 ml-6",attrs:{"status":"positive","pulse":true}}):_c('status-indicator',{staticClass:"mr-2 ml-6",attrs:{"pulse":false}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("OCSP")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.urls_count.ocsp.last))])]),_c('span',{staticClass:"green--text ml-1 mr-1",staticStyle:{"font-size":".7em"}},[_vm._v(_vm._s(_vm.urls_count.ocsp.urls)+" URLs")])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }