<template>

  <CompressedPieChartCard
        id="expiration-chart"
        :data="chart_data"
        :options="chart_options"
        hover-reveal
        type="Pie"
        color="secondary"
        ratio="ct-major-twelfth"
        title="Issued Certificates"
      >
        <template v-slot:reveal-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" color="info" icon v-on="on">
                <v-icon color="info">
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>

            <span>Refresh</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" light icon v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <span>Change Date</span>
          </v-tooltip>
        </template>

        <p class="pa-2 pt-0">
          <table class="table table-striped d-inline-flex font-weight-light ml-2 mt-1" style="font-size:.75em;">
            <tbody>
              <tr>
                <td style="border-left:20px solid #053768;padding-left:5px;">
                  {{ series.not.length }} not yet valid
                </td>
              </tr>
              <tr>
                <td style="border-left:20px solid #ffffcc;padding-left:5px;">
                  {{ series.seven.length }} in the last 7 days
                </td>
              </tr>
              <tr>
                <td style="border-left:20px solid #c2e699;padding-left:5px;">
                  {{ series.thirty.length }} in the last 30 days
                </td>
              </tr>
              <tr>
                <td style="border-left:20px solid #78c679;padding-left:5px;">
                  {{ series.year.length }} in the last 365 days
                </td>
              </tr>
              <tr>
                <td style="border-left:20px solid #238443;padding-left:5px;">
                  {{ series.over.length }} over a year ago
                </td>
              </tr>
            </tbody>
          </table>
        </p>
      </CompressedPieChartCard>

</template>

<script>
import moment from "moment";
import CompressedPieChartCard from "../../CompressedPieChartCard";

export default {
  name: "IssuedCertificatesPieChart",
  components: {CompressedPieChartCard },
  props: ["certificates"],
  data: function() {
    return {
      saving: false,
      saved: false,
      error: false,
      editing: false,
      unsaved: null,
      certs: null,
      certs_loading: true,
      num_certs: null,
      series: {
        not: [],
        already: [],
        seven: [],
        thirty: [],
        year: [],
        over: []
      },
      chart_options: {
        showLabel: false
      }
    };
  },
  computed: {
    graph_series: function() {
      return [
        { value: this.series.not.length, className: "notyet" },
        { value: this.series.seven.length, className: "seven" },
        { value: this.series.thirty.length, className: "thirty" },
        { value: this.series.year.length, className: "year" },
        { value: this.series.over.length, className: "over" }
      ];
    },
    chart_data: function() {
      return {
        labels: [
          "Not yet valid",
          "Expired",
          "< 7 Days",
          "< 30 days",
          "< 365 days",
          "> 365 days"
        ],
        series: this.graph_series
      };
    }
  },
  methods: {
    updateData: function() {
      for (let x of this.certificates) {
        if (moment(x.certificate.valid_from) > moment.now()) {
          this.already.push(x);
          alert("WOAH");
        } else {
          let diff = moment(moment.now()).diff(
            x.certificate.valid_from,
            "days"
          );
          if(diff < 0){
            this.series.not.push(x);
          } else if (diff <= 7) {
            this.series.seven.push(x);
          } else if (diff <= 30) {
            this.series.thirty.push(x);
          } else if (diff <= 365) {
            this.series.year.push(x);
          } else {
            this.series.over.push(x);
          }
        }
      }
    }
  },
  watch: {
    certificates: function() {
      this.updateData();
    }
  },
  created: function() {
    this.updateData();
  }
};
</script>

<style>
.ct-chart .ct-series.stroke-green .ct-bar {
  stroke: green;
}
.ct-chart .ct-series.stroke-yellow .ct-bar {
  stroke: rgba(255, 167, 38, 0.8);
}
.ct-chart .ct-series.stroke-red .ct-bar {
  stroke: rgba(230, 20, 20, 0.8);
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie {
  fill: pink !important;
}
.ct-series-a .ct-slice-pie {
  fill: pink !important;
}
table td {
  padding: 4px;
}
.notyet path {
  fill: #053768 !important;
  stroke: #053768 !important;
}
.seven {
  fill: #ffffcc;
}
.thirty {
  fill: #c2e699;
}
.year {
  fill: #78c679;
}
.over {
  fill: #238443;
}
</style>
