<template>
  <div class="home">
    <v-row class="ma-0" v-if="auth">
      <v-col
        v-if="certificate_settings"
        cols="12"
        md="6"
        v-show="certificate_settings.length > 0"
      >
        <v-row>
          <v-col cols="12" md="6">
            <IssuedCertificatesPieChart :certificates="certificate_settings" />
          </v-col>
          <v-col cols="12" md="6">
            <ExpiringCertificatesPieChart
              :certificates="certificate_settings"
            />
          </v-col>
        </v-row>

        <MaterialCard color="primary">
          <template v-slot:heading>
            <v-card-title class="pa-4">
              Monitored Certificates
              <v-spacer></v-spacer>
              <v-text-field
                hide-details
                dense
                class="mt-2"
                clearable
                append-icon="mdi-magnify"
                aria-label="Search"
                v-model="cert_search"
              >
              </v-text-field>
            </v-card-title>
          </template>
          <v-card-text class="ma-0 pa-0">
            <CertificateTable :search="cert_search" />
          </v-card-text>
        </MaterialCard>
      </v-col>
      <v-col cols="12" md="6" class="pt-2">
        <LiveInstance
          v-if="instance_info"
          :instance_info="instance_info"
          class="mt-6"
        />
        <LiveInstance
          v-if="public_instance_info"
          :instance_info="public_instance_info"
        />
        <div class="mt-4 pt-1">
          <MaterialCard color="primary">
            <template v-slot:heading>
              <v-card-title class="pa-4">
                Monitored URLs
                <v-spacer></v-spacer>
                <v-text-field
                  hide-details
                  dense
                  class="mt-2"
                  append-icon="mdi-magnify"
                  aria-label="Search"
                  v-model="url_search"
                  clearable
                >
                </v-text-field>
              </v-card-title>
            </template>
            <v-card-text class="ma-0 pa-0">
              <UrlTable :search="url_search" />
            </v-card-text>
          </MaterialCard>
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-0" v-else>
      <v-col cols="12" md="3" class="ma-0 pa-0"></v-col>
      <v-col cols="12" md="6">
        <LoginOrRegister />
      </v-col>
      <v-col cols="12" md="3" class="ma-0 pa-0"></v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginOrRegister from "../components/LoginOrRegister";
import CertificateTable from "../components/CertificateTable";
import ExpiringCertificatesPieChart from "../components/charts/pie/certificates/Expiring";
import IssuedCertificatesPieChart from "../components/charts/pie/certificates/Issued";
import MaterialCard from "../components/charts/MaterialCard";
import LiveInstance from "../components/charts/LiveInstance";
import UrlTable from "../components/UrlTable";
export default {
  name: "Home",
  data: function() {
    return {
      log_text: [],
      text_to_send: null,
      url_search: null,
      cert_search: null
    };
  },
  methods: {
    clickButton: function(val) {
      // $socket is [WebSocket](https://developer.mozilla.org/en-US/docs/Web/API/WebSocket) instance
      console.log(val);
      // or with {format: 'json'} enabled
      this.$socket.sendObj({ awesome: "data" });
    }
  },
  mounted() {
    this.$options.sockets.onmessage = data => console.log(data);
  },
  components: {
    UrlTable,
    LiveInstance,
    MaterialCard,
    IssuedCertificatesPieChart,
    ExpiringCertificatesPieChart,
    CertificateTable,
    LoginOrRegister
  },
  computed: {
    auth() {
      return this.$store.getters.ifAuthenticated;
    },
    monitoring_groups() {
      return this.$store.getters.monitoring_groups;
    },
    certificate_settings() {
      return this.$store.getters.certificate_settings;
    },
    instance_info: function() {
      return this.$store.getters.instance_info;
    },
    public_instance_info: function() {
      return this.$store.getters.public_instance_info;
    }
  }
};
</script>
