<template>
  <div>
    <v-data-table
      :headers="table_headers"
      dense
      :items="urls_to_show"
      class="elevation-0"
      flat
      style="border:0;"
      :sort-desc="false"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 100]
      }"
      :options="{ itemsPerPage: page_size }"
      :mobile-breakpoint="0"
      :search="search"
    >
      <template v-slot:item.uri.uri="{ item }">
        <Generic :url="item.uri"></Generic>
      </template>

      <template v-slot:loading>
        <LoadingSVG />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import Generic from "./uris/Generic";

export default {
  name: "UrlTable",
  components: { Generic },
  props: ["urls", "title", "search"],
  data: function() {
    return {
      filter: "Show All",
      filters: ["Show All"],
      loading: false,
      options: { itemsPerPage: 10 },
      default_page_size: 5,
      table_headers: [{ text: "URL", sortable: true, value: "uri.uri" }]
    };
  },
  computed: {
    page_size: function() {
      if (this.default_page_size) {
        return this.default_page_size;
      } else {
        return 10;
      }
    },
    urls_to_show() {
      if (this.urls) {
        return this.urls;
      } else {
        let urls = this.$store.getters.monitored_uris;

        if (urls) {
          return urls;
        } else {
          return [];
        }
      }
    }
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    },
    beforeNow: function(time) {
      return moment(time).isBefore(moment());
    },
    showCertDialog: function(cert) {
      this.$store.dispatch("show_certificate_dialog", cert);
    }
  },
  mounted: function() {},
  watch: {}
};
</script>

<style scoped></style>
