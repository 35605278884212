<template>
  <MaterialCard
    class="v-card--material-chart pt-4"
    v-bind="$attrs"
    v-on="$listeners"
    flat
  >
    <template v-slot:heading>
      <v-row>
        <v-col cols="6" md="5" class="mt-2 pr-0 mr-0">
          <chartist
            :data="data"
            :event-handlers="eventHandlers"
            :options="options"
            :ratio="ratio"
            :responsive-options="responsiveOptions"
            :type="type"
            style="min-height:110px;max-height:180px;margin-bottom:-20px;margin-top:-30px;margin-left:-25px;"
          />
        </v-col>
        <v-col
          cols="6"
          md="7"
          class="text-subtitle-1"
          style="vertical-align:middle;text-align:left;display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;"
        >
          {{ title }}
        </v-col>
      </v-row>
    </template>

    <slot slot="reveal-actions" name="reveal-actions" />

    <slot />

    <slot slot="actions" name="actions" />
  </MaterialCard>
</template>

<script>
import MaterialCard from "./MaterialCard";
export default {
  name: "CompressedPieChartCard",
  components: { MaterialCard },
  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    eventHandlers: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    ratio: {
      type: String,
      default: undefined
    },
    responsiveOptions: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true,
      validator: v => ["Bar", "Line", "Pie"].includes(v)
    },
    title: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style lang="sass">
.v-card--material-chart
  p
    color: #999

  .v-card--material__heading
    max-height: 185px

    .ct-label
      color: inherit
      opacity: .7
      font-size: 0.975rem
      font-weight: 100

    .ct-grid
      stroke: rgba(255, 255, 255, 0.2)

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
        stroke: rgba(255,255,255,.8)

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
        fill: rgba(255,255,255,.4)
</style>
