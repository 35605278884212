<template>
  <div>
    <v-tabs v-model="tab" show-arrows icons-and-text dark grow>
      <v-tabs-slider color="secondary"></v-tabs-slider>
      <v-tab
        v-for="i in tabs"
        :key="i.name"
        style="background:linear-gradient(180deg,#053768,#7192B0) "
      >
        <v-icon large>{{ i.icon }}</v-icon>
        <div class="caption py-1">{{ i.name }}</div>
      </v-tab>
      <v-tab-item>
        <v-card class="px-4">
          <v-card-text>
            <v-alert
              type="info"
              dense
              text
              border="left"
              class="ma-0 mb-2 pa-5"
            >
              Authentication is required to browse in private mode.
            </v-alert>
            <v-form
              ref="loginForm"
              v-model="valid"
              lazy-validation
              @submit.prevent="onSubmit"
              class="pa-4"
            >
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    v-model="email"
                    :rules="loginEmailRules"
                    label="E-mail"
                    required
                    autocomplete="username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'eye' : 'eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    autocomplete="current-password"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-select
                    v-model="instance"
                    required
                    name="input-10-1"
                    label="TrustMonitor Instance"
                    :items="instances"
                    item-text="name"
                    return-object
                  ></v-select>
                </v-col>
                <v-col class="d-flex pa-0 ma-0" cols="12" sm="6" xsm="12">
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                  <v-btn
                    type="submit"
                    block
                    :disabled="!valid"
                    color="success"
                    @click="validate"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="px-4">
          <v-card-text>
            <v-alert
              type="info"
              dense
              text
              border="left"
              class="ma-0 mb-2 pa-5"
            >
              Public registration is disabled in private mode.
            </v-alert>
            <v-form
              ref="registerForm"
              style="display:none"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                    autocomplete="username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show1 = !show1"
                    autocomplete="new-password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    block
                    v-model="verify"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, passwordMatch]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Confirm Password"
                    counter
                    @click:append="show1 = !show1"
                    autocomplete="new-password"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                  <v-btn
                    block
                    :disabled="!valid"
                    color="success"
                    @click="validate"
                    >Register</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "LoginOrRegister.vue",
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    }
  },
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onSubmit() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
        instance: this.instance
      });
    }
  },
  data: () => ({
    dialog: true,
    tab: 0,
    tabs: [
      { name: "Login", icon: "mdi-account" },
      { name: "Register", icon: "mdi-account-outline" }
    ],
    valid: true,

    firstName: "",
    lastName: "",
    email: "keithdreier@gmail.com",
    password: "",
    //password: "8Ou2DFumZNAwmnw3GvCE",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      v => !!v || "Required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    emailRules: [
      v => !!v || "Required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    instance: {
      id: 2,
      name: "Private Demo",
      private: true,
      api_url: "https://tm-private-prod.herokuapp.com/",
      public_api_url: "https://tm-public-prod.herokuapp.com/",
      socket_url: "wss://tm-public-prod/ws/chat/live/"
    },
    instances: [
      {
        id: 1,
        name: "CertiPath Public Demo",
        private: false,
        api_url: "https://tm-public-prod.herokuapp.com/",
        socket_url: "wss://tm-public-prod.herokuapp.com/ws/chat/live/",
        public_api_url: null
      },
      {
        id: 2,
        name: "Private Demo",
        private: true,
        api_url: "https://tm-private-prod.herokuapp.com/",
        public_api_url: "https://tm-public-prod.herokuapp.com/",
        socket_url: "wss://tm-public-prod/ws/chat/live/"
      },
      {
        id: 3,
        name: "localhost:8000",
        private: false,
        api_url: "http://localhost:8000/",
        socket_url: "ws://localhost:8000/ws/chat/live/",
        public_api_url: null
      },
      {
        id: 4,
        name: "localhost:9000",
        private: true,
        api_url: "http://localhost:9000/",
        public_api_url: "http://localhost:8000/",
        socket_url: "ws://localhost:9000/ws/chat/live/"
      }
    ],
    show1: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => (v && v.length >= 8) || "Min 8 characters"
    }
  })
};
</script>

<style scoped></style>
